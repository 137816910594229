import initialCrudState from "@/store/templates/crud/state";

const initialModel = () => ({
  id: null,
  subject: null,
  price: null,
  countries: []
});

export default () => ({
  ...initialCrudState(initialModel)
});
